import React from 'react'
import styles from './styles.module.scss'
import { NftCard } from '../NftCard'
import { Placeholder } from 'semantic-ui-react'
import { NoDataCards } from '../NoDataCards'
import { nftCardTypes } from '../NftCard/typing'

interface nftListTypes {
    cards?: nftCardTypes[]
}

export const NftsList = ({ cards }: nftListTypes) => {
    const renderCards = () =>
        cards &&
        cards.map((item: nftCardTypes, i: number) => (
            <div className={styles.list__item} key={i}>
                <NftCard data={item} />
            </div>
        ))

    const renderPlaceholder = () => (
        <div className={styles.list}>
            {[...Array(6).keys()].map((_, i) => (
                <div key={i} className={`${styles.list__placeholder} ${styles.list__item}`}>
                    <Placeholder>
                        <Placeholder.Image square />
                    </Placeholder>
                </div>
            ))}
        </div>
    )

    return (
        <div className={styles.list}>
            {cards ? cards.length ? renderCards() : <NoDataCards /> : renderPlaceholder()}
        </div>
    )
}
