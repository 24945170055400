import React from 'react'
import { useAuctionTimer } from '../../hooks/auctionTimer'
import { statuses } from '../../store/auction/constants'
import { AuctionTimerProps } from '../AuctionTimer/typings'
import styles from './styles.module.scss'

export const Timer = ({ status, timeLeft, reserve, auctionId }: AuctionTimerProps) => {
    const time = useAuctionTimer(status, timeLeft, auctionId)

    return (
        <div className={styles.mainAuction__auction__timer}>
            {statuses.pending === status || (statuses.active === status && !reserve) ? (
                <>
                    <p className={styles.mainAuction__auction__timer__label}>
                        {statuses.pending === status ? 'Starts in' : 'Ends in'}
                    </p>
                    <p className={styles.mainAuction__auction__timer__time}>
                        <span>{time.days}</span>days
                    </p>
                    <p className={styles.mainAuction__auction__timer__time}>
                        <span>{time.hours}</span>hours
                    </p>
                    <p className={styles.mainAuction__auction__timer__time}>
                        <span>{time.minutes}</span>minutes
                    </p>
                    <p className={styles.mainAuction__auction__timer__time}>
                        <span>{time.seconds}</span>seconds
                    </p>
                </>
            ) : (
                <div className={styles.mainAuction__auction__timer__wrapper}>
                    {reserve && status !== statuses.finished ? (
                        <>
                            <p className={styles.mainAuction__auction__timer__reserve}>BIDDING OPEN NOW</p>
                            <p className={styles.mainAuction__auction__timer__reserve}>
                                (Countdown begins{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={'//blog.rare.market/article/auction-rules'}
                                >
                                    when reserve is met
                                </a>
                                )
                            </p>
                        </>
                    ) : (
                        <p className={styles.mainAuction__auction__timer__label}>Auction finished!</p>
                    )}
                </div>
            )}
        </div>
    )
}
