import React from 'react'
import styles from './styles.module.scss'
import Link from 'next/link'
import { getCloudinaryImg } from '../../helpers'
import { RarityIcon } from '../RarityIcon'
import { nftCardContractDataTypes, nftCardPropsTypes } from './typing'

export const NftCard = ({ data }: nftCardPropsTypes) => {
    const contractData: nftCardContractDataTypes = data.contractData ?? { fee: '', maxSupply: '', totalSupply: '' }

    return (
        <div className={styles.card}>
            <div className={styles.card__image}>
                <img src={getCloudinaryImg(data.cardImg.url, 1280, 1280)} alt="author" />
            </div>
            <div className={styles.card__info}>
                {data.rarity && (
                    <div className={styles.card__info__rare}>
                        <RarityIcon name={data.rarity} />
                    </div>
                )}
                <Link href={`/card/${data.id}`}>
                    <a className={styles.card__info__title}>{data.title ? data.title : data.name}</a>
                </Link>
                <p className={styles.card__info__author}>
                    {data.artist && data.artist.name && `by ${data.artist.name} ${data.artist.secondaryName}`}
                </p>
                <div className={styles.card__info__amount}>
                    <p>
                        <b>{contractData.maxSupply ? contractData.maxSupply : data.maxSupply ? data.maxSupply : '-'}</b>{' '}
                        Total
                    </p>
                    <p>
                        <b>
                            {contractData.maxSupply
                                ? parseFloat(contractData.maxSupply) - parseFloat(contractData.totalSupply)
                                : '-'}
                        </b>{' '}
                        Remain
                    </p>
                </div>

                <div className={styles.card__info__footer}>
                    <Link href={`/card/${data.id}`}>
                        <a className={styles.card__info__footer__button}>Buy now</a>
                    </Link>
                    <div className={styles.card__info__footer__price}>
                        {contractData.fee ? contractData.fee : data.mintEthFee ? data.mintEthFee : '-'} ETH
                    </div>
                </div>
            </div>
        </div>
    )
}
