import React from 'react'
import Link from 'next/link'
import { Icon } from 'semantic-ui-react'
import { RarityIcon } from '../RarityIcon'
import styles from './styles.module.scss'
import { Timer } from './Timer'
import { getMaiAuctionImage, getReserveStatus } from './mainAuction.helper'
import { calcTimeLeft, getAuctionStatus } from '../../store/auction/helpers'
import { AuctionCardTypes } from '../AuctionCard/typing'

export const MainAuction = ({ content }: { content: { nftAuction: AuctionCardTypes } }) => {
    const data = content.nftAuction
    const status = getAuctionStatus(data)
    const timeLeft = calcTimeLeft(false, data, status)
    const auctionImage = getMaiAuctionImage(data)

    return (
        <section className={styles.mainAuction}>
            <img className={styles.mainAuction__img} src={auctionImage} alt="" />
            <h1 className={styles.mainAuction__title}>
                {data.artist.name && `${data.artist.name} ${data.artist.secondaryName}`}
            </h1>
            <div className={styles.mainAuction__auction}>
                <div className={styles.mainAuction__auctionWrapper}>
                    {data.rarity && (
                        <div className={styles.mainAuction__auction__rarity}>
                            <RarityIcon name={data.rarity} />
                        </div>
                    )}
                    <Timer
                        status={status}
                        timeLeft={timeLeft}
                        reserve={getReserveStatus(data.auctionReserve, data.lastBid)}
                        auctionId={data.id}
                    />
                    <Link href={data.id ? `/auction/${data.id}` : '/auctions'}>
                        <a className={styles.mainAuction__auction__bid}>
                            Bid now <Icon name="gavel" />
                        </a>
                    </Link>
                </div>
            </div>
        </section>
    )
}
