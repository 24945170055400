import React, { useEffect, useState } from 'react'
import { Container } from 'semantic-ui-react'
import { getAuctionStatus } from '../../store/auction/helpers'
import { web3ContextTypes } from '../../store/web3Context/reducer'
import { getNftAuctionById } from '../../web3/web3.api'
import { AuctionCard } from '../AuctionCard'
import { AuctionCardTypes } from '../AuctionCard/typing'
import styles from './styles.module.scss'

interface FeaturedAuctionTypes {
    content: {
        auctionsList: AuctionCardTypes[]
        title: string
        text: string
        allAuctions: { url: string; label: string }
    }
    context: web3ContextTypes
}

export const FeaturedAuction = ({ content, context }: FeaturedAuctionTypes) => {
    const [auctionList, setAuctionList] = useState(content.auctionsList)

    const refreshAuctionsWithContractData = async () => {
        const mappedAuctionList = await Promise.all(
            auctionList.map(async (auction) => {
                const contractData = await getNftAuctionById(auction.id, context)

                return {
                    ...auction,
                    contractData,
                }
            })
        )

        setAuctionList(mappedAuctionList)
    }

    useEffect(() => {
        if (context.active) {
            refreshAuctionsWithContractData()
        }
    }, [context.active])

    const renderSliderItems = () => {
        return auctionList.map((item, key) => {
            const status = getAuctionStatus(item, item.contractData)
            item.status = status
            return (
                <div key={key}>
                    <div className={styles.slider__item}>
                        <AuctionCard data={item} isWhite list />
                    </div>
                </div>
            )
        })
    }

    return (
        <div className={styles.container}>
            <Container>
                <h2 className={styles.container__title}>{content.title}</h2>

                <div className={styles.container__header}>
                    <div className={styles.container__header__left}>
                        <p className={styles.container__text}>{content.text}</p>
                    </div>
                    <div className={styles.container__header__right}>
                        <a className={styles.container__link} href={content.allAuctions.url}>
                            {content.allAuctions.label}
                        </a>
                    </div>
                </div>
            </Container>
            {renderSliderItems()}
            <Container>
                <a
                    className={`${styles.container__link} ${styles.container__link__center}`}
                    href={content.allAuctions.url}
                >
                    {content.allAuctions.label}
                </a>
            </Container>
        </div>
    )
}
