import React from 'react'
import styles from './styles.module.scss'
import Img from './nodata.svg'

interface NoDataCardsProps {
    count?: number;
    size?: string;
}

export const NoDataCards = ({ count, size = 'normal' }: NoDataCardsProps) => {
    const arraySize = () => {
        if (!count) {
            if (size === 'middle') return 2
            else if (size === 'large') return 1
            else return 3
        } else return count
    }

    const cardCalss = () => {
        if (size === 'middle') return styles.noData__middleCard
        else if (size === 'large') return styles.noData__largeCard
        else return styles.noData__card
    }

    const renderCards = () =>
        [...Array(arraySize()).keys()].map((_, i) => (
            <div key={i} className={cardCalss()}>
                <img className={styles.noData__img} src={Img} alt="no data" />
            </div>
        ))

    return (
        <div className={styles.noData}>
            <div className={styles.noData__list}>{renderCards()}</div>
        </div>
    )
}
