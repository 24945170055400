import React from 'react'
import { Container } from 'semantic-ui-react'
import styles from './styles.module.scss'
import ReactMarkdown from 'react-markdown'

interface HomeHeaderTypes {
    title: string
    text: string
}

export const HomeHeader = ({ content }: { content: HomeHeaderTypes }) => {
    return (
        <section className={styles.homeHeader}>
            <Container>
                <div className={styles.homeHeader__wrapper}>
                    <div className={styles.homeHeader__col1}>
                        <div className={styles.homeHeader__titleWrapper}>
                            <ReactMarkdown className={styles.homeHeader__title}>{content.title}</ReactMarkdown>
                        </div>
                    </div>
                    <div className={styles.homeHeader__col2}>
                        <ReactMarkdown className={styles.homeHeader__text}>{content.text}</ReactMarkdown>
                    </div>
                </div>
            </Container>
        </section>
    )
}
