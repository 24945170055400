import React, { useEffect, useState } from 'react'
import { Container } from 'semantic-ui-react'
import styles from './styles.module.scss'
import Link from 'next/link'
import { NftsList } from '../NftsList'
import { nftGetCardFee } from '../../web3/web3.api'
import { FeaturedCollectionsTypes } from './typing'
import { nftCardContractDataTypes, nftCardTypes } from '../NftCard/typing'

export const FeaturedCollections = ({ content, context }: FeaturedCollectionsTypes) => {
    const [cardList, setCardList] = useState<nftCardTypes[]>(content.collectibles)

    const refreshCardsWithContractData = async () => {
        const mappedAuctionList: nftCardTypes[] = await Promise.all(
            cardList.map(async (card: nftCardTypes) => {
                const contractData: null | nftCardContractDataTypes = await nftGetCardFee(
                    card.tokenId,
                    card.artistCollection,
                    context
                )

                return {
                    ...card,
                    contractData,
                }
            })
        )

        setCardList(mappedAuctionList)
    }

    useEffect(() => {
        if (context.active) {
            refreshCardsWithContractData()
        }
    }, [context.active])
    
    return (
        <div id="collections" className={styles.container}>
            <Container>
                <h2 className={styles.container__title}>{content.title}</h2>
                <p className={styles.container__subtitle}>{content.subTitle}</p>

                <div className={styles.container__header}>
                    <div className={styles.container__header__left}>
                        <p className={styles.container__text}>{content.text}</p>
                    </div>
                    <div className={styles.container__header__right}>
                        <Link href={content.allCollectibles ? content.allCollectibles.url : '/collections'}>
                            <a className={styles.container__link}>
                                {content.allCollectibles ? content.allCollectibles.label : 'All collectibles'}
                            </a>
                        </Link>
                    </div>
                </div>

                <NftsList cards={cardList} />

                <Link href={content.allCollectibles ? content.allCollectibles.url : '/collections'}>
                    <a className={`${styles.container__link} ${styles.container__link__center}`}>
                        {content.allCollectibles ? content.allCollectibles.label : 'All collectibles'}
                    </a>
                </Link>
            </Container>
        </div>
    )
}
