import { ComponentTypes, preapreHomePage } from './page.helpers'
import { MainAuction } from '../components/MainAuctions'
import { FeaturedAuction } from '../components/FeaturedAuction'
import { HomeHeader } from '../components/HomeHeader'
import { FeaturedCollections } from '../components/FeaturedCollections'
import { useSelector } from 'react-redux'
import { ContextInitialStateTypes } from '../store/web3Context/reducer'
import config from '../config/config'

interface HomeProps {
    components: ComponentTypes
}

export default function Home({ components }: HomeProps) {
    const { context } = useSelector(({ web3Context }: { web3Context: ContextInitialStateTypes }) => web3Context)

    return (
        <>
            {components.mainAuction ? <MainAuction content={components.mainAuction} /> : ''}
            {components.jumbotron ? <HomeHeader content={components.jumbotron} /> : ''}
            {components.featuredAuctions ? (
                <FeaturedAuction context={context} content={components.featuredAuctions} />
            ) : (
                ''
            )}
            {components.featuredCollections ? (
                <FeaturedCollections context={context} content={components.featuredCollections} />
            ) : (
                ''
            )}
        </>
    )
}

export const getStaticProps = async () => {
    const components = await preapreHomePage()
    return {
        props: {
            components,
        },
        revalidate: +config.CACHE_TIME,
    }
}
