import React from 'react'
import styles from '../Auction/styles.module.scss'
import { Container, Icon } from 'semantic-ui-react'
import Link from 'next/link'
import { convertDate, convertETH } from '../../helpers'
import { RarityIcon } from '../RarityIcon'
import { statuses } from '../../store/auction/constants'
import { AuctionTimer } from '../AuctionTimer'
import { calcTimeLeft, getAuctionImage } from '../../store/auction/helpers'
import { AuctionCardPropsTypes } from './typing'
import { getReserveStatus } from '../MainAuctions/mainAuction.helper'

export const AuctionCard = ({ account, data, title, isWhite, artistName, list }: AuctionCardPropsTypes) => {
    const contractData = data.contractData ?? null
    const { status } = data
    const timeLeft = calcTimeLeft(contractData, data, status)

    return (
        <div
            className={`${styles.auctionCard} ${isWhite ? styles.auctionCardWhite : ''} ${
                title ? styles.auctionCardTiteled : ''
            } ${list ? styles.auctionCardList : ''}`}
        >
            <Container>
                {title && <h2 className={styles.auctionCard__title}>{title}</h2>}
                <div className={styles.auctionCard__wrapper}>
                    <img
                        className={styles.auctionCard__img}
                        src={getAuctionImage(data).src}
                        alt={getAuctionImage(data).alt}
                    />
                    <div className={styles.auctionCard__content}>
                        <Link href={`/auction/${data.id}`}>
                            <a className={styles.auctionCard__content__title}>{data.title ? data.title : data.name}</a>
                        </Link>

                        <h3 className={styles.auctionCard__content__author}>
                            By
                            <Link href={`/artist/${data.artist && data.artist.id}`}>
                                <a>
                                    {data.artist && data.artist.name
                                        ? `${data.artist.name} ${data.artist.secondaryName}`
                                        : artistName
                                        ? artistName
                                        : ''}
                                </a>
                            </Link>
                        </h3>
                        {data.rarity && (
                            <p className={styles.auctionCard__content__unique}>
                                <span className={styles.auctionCard__content__unique__icon}>
                                    <RarityIcon name={data.rarity} />
                                </span>
                                THIS IS A {data.rarity.toUpperCase()} WORK
                            </p>
                        )}
                        <p className={styles.auctionCard__content__label}>
                            Category:{' '}
                            <span className={styles.auctionCard__content__label__category}>{data.category}</span>
                        </p>
                        {data.mediaDetails && (
                            <p className={styles.auctionCard__content__label}>
                                Media: <span>{data.mediaDetails}</span>
                            </p>
                        )}
                        <p className={styles.auctionCard__content__label}>
                            Started on:{' '}
                            <span>{data.startTimestamp ? convertDate(new Date(data.startTimestamp)) : '-'}</span>
                        </p>
                        <div className={styles.auctionCard__content__infoWrapper}>
                            <div>
                                {status === statuses.finished ? (
                                    contractData && contractData.highestBid !== '0' ? (
                                        <>
                                            <p className={styles.auctionCard__content__price}>
                                                Winning bid:{' '}
                                                <span>{convertETH(contractData.highestBid).toFixed(4)} ETH</span>
                                            </p>
                                            {contractData.highestBidder === account && (
                                                <p className={styles.auctionCard__content__info}>You are the winner</p>
                                            )}
                                        </>
                                    ) : (
                                        <p className={styles.auctionCard__content__price}>
                                            Initial price:{' '}
                                            <span>
                                                {contractData && contractData.startBidWei
                                                    ? convertETH(contractData.startBidWei)
                                                    : data.startBid}{' '}
                                                ETH
                                            </span>
                                        </p>
                                    )
                                ) : (
                                    <>
                                        {contractData && contractData.highestBid && contractData.highestBid !== '0' ? (
                                            <>
                                                <p className={styles.auctionCard__content__price}>
                                                    Last bid:{' '}
                                                    <span>{convertETH(contractData.highestBid).toFixed(4)} ETH</span>
                                                </p>
                                                {contractData.highestBidder === account && (
                                                    <p className={styles.auctionCard__content__info}>
                                                        You are the highest bidder
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <p className={styles.auctionCard__content__price}>
                                                Initial price:{' '}
                                                <span>
                                                    {contractData &&
                                                    contractData.startBidWei &&
                                                    contractData.startBidWei !== '0'
                                                        ? convertETH(contractData.startBidWei)
                                                        : data.startBid}{' '}
                                                    ETH
                                                </span>
                                            </p>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        <AuctionTimer
                            status={status}
                            timeLeft={timeLeft}
                            isWhite={isWhite}
                            reserve={getReserveStatus(data.auctionReserve, data.lastBid)}
                            auctionId={data.id}
                        />

                        <div className={styles.auctionCard__content__controls}>
                            <Link href={`/auction/${data.id}`}>
                                <a className={styles.auctionCard__content__controls__bid}>
                                    {account && !(status === statuses.finished || status === statuses.pending) ? (
                                        <>
                                            Bid now
                                            <Icon name="gavel" />{' '}
                                        </>
                                    ) : (
                                        'View details'
                                    )}
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
